import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, get, set } from "firebase/database";
import { getAuth } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './GlobalSettingsModal.css';
import ColorVault from './ColorVault';
import BackgroundColorChanger from './BackgroundColorChanger';

const fontOptions = ["Coolvetica", "Mont", "Garet", "Tondu", "Urbanist", "Bloque", "Classic", "Gong",  "Voltaire", "Vera", "Dream", "Rosehot", "Belgiano", "Arthead", "Woody", "Curvey", "Posh", "Boho", "Money", "Moonic", "Buda", "Valerie", "Times", "Tallboy"];
const smallFontOptions = ["Helvetica", "Poppins", "Roboto", "Writer", "Jost", "Open Sans", "Montserrat", "Lato", "Inter", "Raleway", "Nunito Sans", "Arial", "Caviar", "Creato", "Luis", "Gontserrat"]; // Liste der Schriftarten für Flusstext

const GlobalSettingsModal = ({ show, onClose, pageId }) => {
    const defaultBigFont = "Voyage"; // Setzen Sie hier Ihre Standard-Schriftart fest
    const defaultSmallFont = "Jost"; 

    const [pageSettings, setPageSettings] = useState({
        name: '',
        description: '',
        customDomain: '',
        isActive: false,
        globalBigFont: defaultBigFont,
        globalSmallFont: defaultSmallFont,
        whatsappStatus: false, 
        whatsappNumber: '', 
        translate1: false, // Hinzugefügt
        translate2: false, // Hinzugefügt
        colors: {
            color1: '#FFFFFF',
            color2: '#000000',
            color3: '#FF0000',
            color4: '#00FF00',
        },
    });
    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [previousCustomDomain, setPreviousCustomDomain] = useState('');
    const [selectedFont, setSelectedFont] = useState('');
    const [selectedSmallFont, setSelectedSmallFont] = useState(''); // Zustand für ausgewählte kleine Schriftart
    const [uploadProgress, setUploadProgress] = useState(0); // Fortschrittszustand für das Upload
    const [imageURL, setImageURL] = useState(''); // Zustand für die gespeicherte Bild-URL
    const [isUploading, setIsUploading] = useState(false);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.log("Keine Datei ausgewählt.");
            return;
        }

        setIsUploading(true); // Upload beginnt

        const storage = getStorage();
        const storagePath = `settingsImages/${pageId}/${file.name}`;
        const imageFileRef = storageRef(storage, storagePath);

        const uploadTask = uploadBytesResumable(imageFileRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.log(error);
                setIsUploading(false); // Bei Fehler Upload beenden
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setImageURL(downloadURL);
                    setPageSettings(prevSettings => ({
                        ...prevSettings,
                        livePageImg: downloadURL
                    }));
                    setIsUploading(false); // Upload erfolgreich beendet
                    setUploadProgress(0); // Reset progress
                });
            }
        );
    };

    const checkDomainUniqueness = async (domainName) => {
        if (!domainName) return true; // Keine Überprüfung, wenn kein Name eingegeben wurde
        const publicPageRef = dbRef(db, `/publicPages/${domainName}`);
        const snapshot = await get(publicPageRef);
        return !snapshot.exists(); // True, wenn der Name eindeutig ist
    };

    const handleSmallFontChange = (font) => {
        setSelectedSmallFont(font);
        setPageSettings(prevSettings => ({
            ...prevSettings,
            globalSmallFont: font
        }));
    };

    const handleColorChange = (colorKey, newValue) => {
        const newColors = { ...pageSettings.colors, [colorKey]: newValue };
        setPageSettings(prevSettings => ({ ...prevSettings, colors: newColors }));
    };

    const handleBackgroundColorChange = (color) => {
        setPageSettings(prevSettings => ({ ...prevSettings, backgroundColor: color }));
    };

    useEffect(() => {
        if (show && currentUser && pageId) {
            const pageRef = dbRef(db, `/pages/${pageId}`);
            get(pageRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setPageSettings(data);
                    setImageURL(data.livePageImg || '');
                    setPreviousCustomDomain(data.customDomain); // Setze den vorherigen Domainnamen
                    setSelectedFont(data.globalBigFont);
                    setSelectedSmallFont(data.globalSmallFont); // Setze die vorherige kleine Schriftart
                }
            });
        }
    }, [show, currentUser, pageId, db]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === "customDomain") {
            const sanitizedValue = value.replace(/[^a-z0-9-]/gi, '').toLowerCase(); // Entfernt unerlaubte Zeichen und wandelt in Kleinbuchstaben um
            setPageSettings(prevSettings => ({
                ...prevSettings,
                [name]: sanitizedValue
            }));
        } else if (name === "whatsappNumber") {
            const newValue = value.replace(/[^0-9]/g, ''); // Entfernt alles außer Ziffern
            setPageSettings(prevSettings => ({
                ...prevSettings,
                [name]: newValue
            }));
        } else if (name === "translate1") {
            setPageSettings(prevSettings => ({
                ...prevSettings,
                translate1: checked,
                translate2: !checked ? prevSettings.translate2 : false,
            }));
        } else if (name === "translate2") {
            setPageSettings(prevSettings => ({
                ...prevSettings,
                translate2: checked,
                translate1: !checked ? prevSettings.translate1 : false,
            }));
        } else {
            setPageSettings(prevSettings => ({
                ...prevSettings,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    const saveSettings = async () => {
        if (currentUser && pageId) {
            // Wenn der Domainname unverändert bleibt, überspringe die Eindeutigkeitsprüfung
            if (pageSettings.customDomain !== previousCustomDomain) {
                const isUnique = await checkDomainUniqueness(pageSettings.customDomain);
                if (!isUnique) {
                    alert('Dieser Domainname ist bereits vergeben. Bitte wählen Sie einen anderen.');
                    return;
                }
            }

            const updatedSettings = {
                ...pageSettings,
                globalBigFont: selectedFont || defaultBigFont,
                globalSmallFont: selectedSmallFont || defaultSmallFont,
            };

            const pageRef = dbRef(db, `/pages/${pageId}`);

            // Entferne den alten Domainnamen aus /publicPages, falls er geändert wurde und nicht leer ist
            if (previousCustomDomain && pageSettings.customDomain !== previousCustomDomain) {
                const oldPublicPageRef = dbRef(db, `/publicPages/${previousCustomDomain}`);
                set(oldPublicPageRef, null);
            }

            // Speichere den neuen Domainnamen in /publicPages, wenn er geändert wurde und nicht leer ist
            if (pageSettings.customDomain && pageSettings.customDomain !== previousCustomDomain) {
                const publicPageRef = dbRef(db, `/publicPages/${pageSettings.customDomain}`);
                await set(publicPageRef, { pageId: pageId, userId: currentUser.uid });
            }

            // Speichere die aktualisierten Seiteneinstellungen in /pages
            set(pageRef, updatedSettings).then(() => {
                console.log('Settings updated successfully');
                // Aktualisiere previousCustomDomain, um den neuen Zustand zu reflektieren
                setPreviousCustomDomain(pageSettings.customDomain);
                onClose();
            }).catch(error => {
                console.error('Error updating settings:', error);
            });
        }
    };

    return (
        <div className="modal-background-big">
            <div className="modal-content-big">
                <h2>Page Settings: {pageSettings.name}</h2>
                <div className="modal-content-inner">

                    {/* Allgemeine Einstellungen */}
                    <div className="modal-content-left">
                        <div className="settings-group">
                            <h3>Custom Logo</h3>
                            <label className="custom-upload-box" htmlFor="imageUpload" style={{ height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '300px', padding: '10px' }}>
                                <input
                                    id="imageUpload"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                />

                                {isUploading && (
                                    <div className="spinner-container">
                                        <div className="spinner-circle"></div>
                                        <p>Uploading... {Math.round(uploadProgress)}%</p>
                                    </div>
                                )}

                                {!isUploading && imageURL && (
                                    <img className="uploaded-image-preview" src={imageURL} alt="Vorschau" style={{ maxHeight: '100px' }} />
                                )}

                                {!isUploading && !imageURL && (
                                    <div className="upload-image-preview">
                                        <img src="/img/upload1.svg" alt="Upload Icon" />
                                        <span>Click here to upload your logo</span>
                                    </div>
                                )}
                            </label>
                        </div>
                        <div className="settings-group">
                            <h3>Standard Settings</h3>
                            <label>Page Name</label>
                            <input type="text" name="name" value={pageSettings.name} onChange={handleChange} placeholder="Page Name" />
                            <label>Page Description</label>
                            <textarea name="description" value={pageSettings.description} onChange={handleChange} placeholder="Description" />
                            <label>Page Link</label>
                            <div className="custom-domain-input">
                                <span className="static-text">bonusclub.link/to/</span>
                                <input
                                    type="text"
                                    name="customDomain"
                                    value={pageSettings.customDomain}
                                    onChange={handleChange}
                                    placeholder="Custom Domain"
                                />
                            </div>
                            <label style={{ marginTop: '30px' }}>WhatsApp Button</label>
                            <label className="switch" style={{ marginTop: '12px' }}>
                                <input
                                    type="checkbox"
                                    name="whatsappStatus"
                                    checked={pageSettings.whatsappStatus}
                                    onChange={handleChange}
                                />
                                <span className="slider round"></span>
                            </label>
                            <label>WhatsApp Number (Country Code without +)</label>
                            <input
                                type="text"
                                name="whatsappNumber"
                                value={pageSettings.whatsappNumber}
                                onChange={handleChange}
                                placeholder="491701234567" // Beispiel-Platzhalter
                            />
            <label style={{ marginTop: '30px' }}>Google Translate (White)</label>
                            <label className="switch" style={{ marginTop: '12px' }}>
                                <input
                                    type="checkbox"
                                    name="translate2"
                                    checked={pageSettings.translate2}
                                    onChange={handleChange}
                                />
                                <span className="slider round"></span>
                            </label>
                            <label style={{ marginTop: '30px' }}>Google Translate (Dark)</label>
                            <label className="switch" style={{ marginTop: '12px' }}>
                                <input
                                    type="checkbox"
                                    name="translate1"
                                    checked={pageSettings.translate1}
                                    onChange={handleChange}
                                />
                                <span className="slider round"></span>
                            </label>
                

                            <h3>Colors</h3>
                            <BackgroundColorChanger color={pageSettings.backgroundColor} onChange={handleBackgroundColorChange} />

                            <label>Main Colors</label>
                            <ColorVault colors={pageSettings.colors} onColorChange={handleColorChange} />

                        </div>
                    </div>
                    <div className="modal-content-right">

                        {/* Schriftartenauswahl */}
                        <p className="select-font-text" style={{ marginBottom: '10px' }}>Title Font</p>
                        <div className="settings-fonts">
                            <div className="font-grid">
                                {fontOptions.map(font => (
                                    <div key={font} id={font.toLowerCase()} className={`font-option ${selectedFont === font ? 'selected' : ''}`} onClick={() => setSelectedFont(font)} style={{ fontFamily: font }}>{font}</div>
                                ))}
                            </div>
                        </div>
                        <p className="select-font-text" style={{ marginBottom: '10px', }}>Text Font</p>
                        <div className="settings-fonts">
                            <div className="font-grid">
                                {smallFontOptions.map(font => (
                                    <div key={font} id={font.toLowerCase()} className={`font-option ${selectedSmallFont === font ? 'selected' : ''}`} onClick={() => handleSmallFontChange(font)} style={{ fontFamily: font }}>{font}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className="modal-buttons">
                    <button onClick={onClose}>Close</button>
                    <button id="save-button" onClick={saveSettings}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default GlobalSettingsModal;
