import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue, update } from "firebase/database";

function AllPages() {
    const [pages, setPages] = useState([]);
    const db = getDatabase();

    useEffect(() => {
        const pagesRef = dbRef(db, '/pages');
        const unsubscribe = onValue(pagesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const pagesArray = Object.entries(data).map(([id, pageData]) => ({
                    id,
                    ...pageData,
                }));
                setPages(pagesArray);
            } else {
                setPages([]);
            }
        });

        return () => unsubscribe();
    }, [db]);

    const toggleIsDemo = (pageId, currentStatus) => {
        const pageRef = dbRef(db, `/pages/${pageId}`);
        update(pageRef, { isDemo: !currentStatus })
            .then(() => {
                console.log(`Page ${pageId} isDemo status updated to ${!currentStatus}`);
            })
            .catch((error) => {
                console.error("Error updating isDemo status:", error);
            });
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>All Pages</h2>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                <thead>
                    <tr style={{ borderBottom: '1px solid #000' }}>
                        <th>Custom Domain</th>
                        <th>User ID</th>
                        <th>View Counter</th>
                        <th>isDemo</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {pages.length > 0 ? (
                        pages.map((page) => (
                            <tr key={page.id}>
                                <td>{page.customDomain || 'No Domain'}</td>
                                <td>{page.userId || 'Unknown User'}</td>
                                <td>{page.viewCounter || 0}</td>
                                <td>{page.isDemo ? 'True' : 'False'}</td>
                                <td>
                                    <button
                                        onClick={() => toggleIsDemo(page.id, page.isDemo)}
                                        style={{ padding: '5px 10px', cursor: 'pointer' }}
                                    >
                                        Toggle isDemo
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" style={{ textAlign: 'center' }}>No pages found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default AllPages;
