import React, { useState } from 'react';
import PremiumToggle from './PremiumToggle';
import AddService from './AddService';
import DashboardServiceList from './DashboardServiceList';
import AddCredits from './AddCredits';
import AddManager from './AddManager';
import MarketingDatabase from './MarketingDatabase';
import AllPages from './AllPages';
import './TeamDashboard.css';

function TeamDashboard() {
    const [currentComponent, setCurrentComponent] = useState('PremiumToggle');
    const [editingServiceId, setEditingServiceId] = useState(null);

    const handleEditService = (serviceId) => {
        setEditingServiceId(serviceId);
        setCurrentComponent('AddService');
    };

    const handleSaveService = () => {
        setEditingServiceId(null);
        setCurrentComponent('DashboardServiceList');
    };

    const renderComponent = () => {
        switch (currentComponent) {
            case 'PremiumToggle':
                return <PremiumToggle />;
            case 'AddService':
                return <AddService serviceId={editingServiceId} onSave={handleSaveService} />;
            case 'DashboardServiceList':
                return <DashboardServiceList onEdit={handleEditService} />;
            case 'AddCredits':
                return <AddCredits />;
            case 'AddManager':
                return <AddManager />;
            case 'MarketingDatabase':
                return <MarketingDatabase />;
            case 'AllPages':
                return <AllPages />;
            default:
                return null;
        }
    };

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <button onClick={() => setCurrentComponent('PremiumToggle')}>Premium Toggle</button>
                <button onClick={() => setCurrentComponent('AddService')}>Add Service</button>
                <button onClick={() => setCurrentComponent('DashboardServiceList')}>Service List</button>
                <button onClick={() => setCurrentComponent('AddCredits')}>Add Credits</button>
                <button onClick={() => setCurrentComponent('AddManager')}>Add Manager</button>
                <button onClick={() => setCurrentComponent('MarketingDatabase')}>Marketing Database</button>
                <button onClick={() => setCurrentComponent('AllPages')}>All Pages</button>
            </div>
            {renderComponent()}
        </div>
    );
}

export default TeamDashboard;