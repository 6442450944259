import React, { useState, useEffect } from 'react';
import './ImageSizeSlider.css';

function debounce(func, wait) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
}

const ImageSizeSlider = ({ label, width, setWidth, min = 0, max = 500 }) => {
    const [sliderValue, setSliderValue] = useState(width);

    useEffect(() => {
        setSliderValue(width);
    }, [width]);

    const debouncedSetWidth = debounce(setWidth, 100);

    const handleSliderChange = (e) => {
        const value = e.target.value;
        setSliderValue(value);
        debouncedSetWidth(value);
    };

    const handleInputChange = (e) => {
        let value = e.target.value.replace(/[^0-9.]/g, ''); // Allow numbers and decimals
        value = Math.max(min, Math.min(max, parseFloat(value) || 0)); // Clamp the value
        setSliderValue(value);
        debouncedSetWidth(value);
    };

    return (
        <div className="image-size-slider-container">
            <label className="slided-label">{label}</label>
            <input
                type="range"
                min={min}
                max={max}
                value={sliderValue}
                className="slided"
                id="image-size-slider"
                onChange={handleSliderChange}
            />
            <input
                type="text"
                className="slided-value-input"
                value={sliderValue || ''} // Sicherstellen, dass der Wert nicht undefined ist
                onChange={handleInputChange}
            />
        </div>
    );
};

export default ImageSizeSlider;