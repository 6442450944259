import React, { useState } from 'react';
import './TopWidgetEdit.css';
import ImageSizeSlider from '../ImageSizeSlider';
import LineHeightSlider from '../LineHeightSlider';
import StyleSelector from '../StyleSelector';

function TitleWidgetEdit({ widget, onUpdate, colors }) {
    const [editData, setEditData] = useState({ ...widget });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedEditData = { ...editData, [name]: value };
        setEditData(updatedEditData);
        onUpdate(updatedEditData);
    };

    const handleSliderChange = (name, value) => {
        const updatedEditData = { ...editData, [name]: value };
        setEditData(updatedEditData);
        onUpdate(updatedEditData);
    };

    const handleTextAlignChange = (alignment) => {
        const updatedEditData = { ...editData, textAlign: alignment };
        setEditData(updatedEditData);
        onUpdate(updatedEditData);
    };

    return (
        <div>
            <div className="widget-trenner" style={{ marginTop: '20px' }}>
                <h3>{editData.name}</h3>
                <hr className="hrrrr" />
            </div>
            <div className="top-widget-edit-container">
                <div className="input-group">
                    <label htmlFor="text1">Text 1</label>
                    <input
                        id="text1"
                        type="text"
                        name="text1"
                        value={editData.text1}
                        onChange={handleInputChange}
                        className="widgetInputField"
                    />
                </div>

                {/* Text Alignment Section */}
             
                {/* Colors Section */}
                <div className="input-group">
                    <div className="widget-trenner" style={{ marginTop: '30px', marginBottom: '0px', display: 'flex' }}>
                        <h3>Colors</h3>
                        <div className="widget-color-previews">
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color1 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color2 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color3 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color4 }}></div>
                        </div>
                    </div>
                    <hr className="hrrrr" />
                </div>
                <div id="double_color_picker">
                    <div className="input-group">
                        <label htmlFor="textColor1">Text Big</label>
                        <div className="colorPicker">
                            <input
                                id="textColor1"
                                type="color"
                                name="textColor1"
                                value={editData.textColor1}
                                onChange={handleInputChange}
                                className="color-input"
                            />
                            <div
                                className="custom-color-display-2"
                                style={{ backgroundColor: editData.textColor1 }}
                            ></div>
                            <img className="color-picker-icon" src="/img/text-fat.svg" alt="" />
                        </div>
                    </div>

                    <div className="input-group">
                        <label htmlFor="backgroundColor">Background</label>
                        <div className="colorPicker">
                            <input
                                id="backgroundColor"
                                type="color"
                                name="backgroundColor"
                                value={editData.backgroundColor}
                                onChange={handleInputChange}
                                className="color-input"
                            />
                            <div
                                className="custom-color-display-2"
                                style={{ backgroundColor: editData.backgroundColor }}
                            ></div>
                            <img className="color-picker-icon" src="/img/background.svg" alt="" />
                        </div>
                    </div>
                </div>

                {/* Fonts Section */}
                <div className="widget-trenner">
                    <h3>Fonts</h3>
                    <hr className="hrrrr" />
                </div>
                <div className="input-container">
                    <div className="input-custom-component">
                        <h3>Text Big</h3>
                        <div className="input-group">
                            <label htmlFor="text1Size">Size</label>
                            <input
                                id="text1Size"
                                type="text"
                                name="text1Size"
                                value={editData.text1Size}
                                onChange={handleInputChange}
                                className="text-component-1"
                            />
                        </div>
                        <hr className="hrrrrr" />
                        <div className="input-group">
                            <label htmlFor="text1Weight" id="alt-label">Thick</label>
                            <input
                                id="text1Weight"
                                type="text"
                                name="text1Weight"
                                value={editData.text1Weight}
                                onChange={handleInputChange}
                                className="text-component-2"
                            />
                        </div>
                    </div>
                </div>

                {/* Background Height Slider */}
                <div className="widget-trenner">
                    <h3>More Settings</h3>
                    <hr className="hrrrr" />
                    <div className="input-group">
                    <label style={{marginTop: '30px'}}>Text Alignment</label>
                    <div className="text-align-buttons">
                        <button
                            onClick={() => handleTextAlignChange('left')}
                            className={editData.textAlign === 'left' ? 'active' : ''}
                        >
                            Left
                        </button>
                        <button
                            onClick={() => handleTextAlignChange('center')}
                            className={editData.textAlign === 'center' ? 'active' : ''}
                        >
                            Center
                        </button>
                        <button
                            onClick={() => handleTextAlignChange('right')}
                            className={editData.textAlign === 'right' ? 'active' : ''}
                        >
                            Right
                        </button>
                    </div>
                </div>

                {/* Letter Spacing Slider */}
                <div className="widget-trenner">


                    <ImageSizeSlider
                        min="0"
                        max="50"
                        label="Letter Spacing"
                        width={editData.letterSpacing || 0}
                        setWidth={(value) => handleSliderChange('letterSpacing', value)}
                    />
                </div>

                {/* Line Height Slider */}
                <div className="widget-trenner">

                    <LineHeightSlider
                        min="1"
                        max="3"
                        step="0.1"
                        label="Line Height"
                        value={editData.lineHeight || 1.5}
                        setValue={(value) => handleSliderChange('lineHeight', value)}
                    />
                </div>

                  
                  
                  
                    <label style={{ fontSize: '12px', textTransform: 'uppercase', color: 'grey', marginTop: '30px' }}>
                        Background Height
                    </label>

                    <ImageSizeSlider
                        min="30"
                        max="1000"
                        label=""
                        width={editData.height}
                        setWidth={(value) => handleInputChange({ target: { name: 'height', value } })}
                    />
                </div>

                {/* Spacing Section */}
                <div className="input-container">
                    <div className="input-group">
                        <label htmlFor="marginTop">Space Top</label>
                        <input
                            id="marginTop"
                            type="text"
                            name="marginTop"
                            value={editData.marginTop}
                            onChange={handleInputChange}
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/element.svg" alt="" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="marginBottom">Space Bottom</label>
                        <input
                            id="marginBottom"
                            type="text"
                            name="marginBottom"
                            value={editData.marginBottom}
                            onChange={handleInputChange}
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/element.svg" style={{ rotate: '180deg' }} alt="" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="marginBottom">Space Left</label>
                        <input
                            id="marginLeft"
                            type="text"
                            name="marginLeft"
                            value={editData.marginLeft}
                            onChange={handleInputChange}
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/element.svg" style={{ rotate: '270deg' }} alt="" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="marginBottom">Space Right</label>
                        <input
                            id="marginRight"
                            type="text"
                            name="marginRight"
                            value={editData.marginRight}
                            onChange={handleInputChange}
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/element.svg" style={{ rotate: '90deg' }} alt="" />
                    </div>
                </div>

                <div className="save-button-banner"></div>
            </div>
        </div>
    );
}

export default TitleWidgetEdit;
