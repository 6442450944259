export const widgetTemplates = {
    widget1: {
        imgBanner: '',  // Pfad zum Standard-Bannerbild
        imgLogo: '',      // Pfad zum Standard-Logobild
        imagePreview: '/img/preview-1.webp', // Pfad zum Vorschaubild für Widget1
        text1: 'Welcome to our Hotel',               // Standardtext 1
        text2: 'This is a short description text of how happy we are to see you at our hotel. We will try our best to give you an unforgettable experience.',
        logoWidth: '40',
        imageWidth: '350',               // Standardtext 2
        textColor1: '#000000',            // Standardtextfarbe 1 (schwarz)
        textColor2: '#7b7b7b',            // Standardtextfarbe 2 (dunkelgrau)
        text1Size: '42',                // Standardgröße für Text 1
        text2Size: '20',                // Standardgröße für Text 2
        text1Weight: '500',              // Standardgewicht für Text 1
        text2Weight: '300',            // Standardgewicht für Text 2
        backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
        marginBottom: '40', 
        marginTop: '0',            // Standardunterer Abstand
        id: '',                    // Eindeutige ID für das Widget
        type: 'Widget1',                  // Typ des Widgets
        name: 'Top-Widget',               // Anzeigename des Widgets
        position: '1',                      // Standardposition
        isSelected: false,
        cat1: 'Fancy'   , 
        cat2: 'Image',           // Auswahlstatus initial auf false setzen
      },
      widget16: {
        imgBanner: '',  // Pfad zum Standard-Bannerbild
        imgLogo: '',      // Pfad zum Standard-Logobild
        imagePreview: '/img/bakaya_1.webp', // Pfad zum Vorschaubild für Widget1
        text1: 'SPA',               // Standardtext 1
        text2: 'A tranquil oasis where the gentle flow of water and the soothing melodies of nature invite your soul to a journey of healing, renewal, and profound peace.',
        logoWidth: '160',
        imageWidth: '350',               // Standardtext 2
        textColor1: '#000000',            // Standardtextfarbe 1 (schwarz)
        textColor2: '#2B2B2B', 
        headerStyle: 'style4',           // Standardtextfarbe 2 (dunkelgrau)
        text1Size: '56',  
        height: '500',
        backgroundOpacity: '0.8',              // Standardgröße für Text 1
        text2Size: '20',                // Standardgröße für Text 2
        text1Weight: '500',              // Standardgewicht für Text 1
        text2Weight: '300',            // Standardgewicht für Text 2
        backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
        marginBottom: '40', 
        marginTop: '0',            // Standardunterer Abstand
        id: '',                    // Eindeutige ID für das Widget
        type: 'Widget16',                  // Typ des Widgets
        name: 'Background-Image',               // Anzeigename des Widgets
        position: '1',                      // Standardposition
        isSelected: false,
        cat1: 'Fancy'   , 
        cat2: 'Image',           // Auswahlstatus initial auf false setzen
      },
      
      widget4: {
        img: '',
        imagePreview: '/img/preview-4.png',
        logoWidth: '150',      // Pfad zum Vorschaubild für Widget1
        text1: 'MyHotelWifi',
        text2: 'MYHOTELPW1234',
        text3: 'FREE WIFI',
        textColor1: '#000000',
        textColor2: '#333333',
        textColor3: '#FFFFFF',
        textBackground: '#F5BF32',
        borderColor: '#000000',
        border: false, 
        text1Weight: '400',
        text2Weight: '500',
        text1Size: '28',
        text2Size: '20',
        backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
        marginBottom: '20',             // Standardunterer Abstand
        marginTop: '0',   
        borderRadius: '25',           // Standardunterer Abstand      // Standardunterer Abstand
        shadow: false,          // Standardunterer Abstand
        id: '',
        position: '2',
        type: 'Widget4',
        name: 'Wifi Card',
        isSelected: false,
        cat1: 'Fancy' , 
        cat2: 'Image',  
        inverted: false,
      },

    widget3: {
      img: '',
      imagePreview: '/img/minibar.webp',
      logoWidth: '70',      // Pfad zum Vorschaubild für Widget1
      text1: 'Minibar',
      text2: 'Get yourself a nice little snack from our minibar. Open it up, and find a little gift.',
      textColor1: '#000000',
      textColor2: '#7b7b7b',
      text1Weight: '500',
      text2Weight: '300',
      text1Size: '28',
      text2Size: '20',
      borderColor: '#e3e3e3',       // Standardhintergrundfarbe (weiß)
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20',             // Standardunterer Abstand
      marginTop: '0px',   
      borderRadius: '25',          // Standardunterer Abstand
      id: '',
      position: '2',
      type: 'Widget3',
      name: 'Icon Feature',
      isSelected: false,
      cat1: 'Fancy'   , 
      cat2: 'Image',
      inverted: false,
      border: false, 
      shadow: true,          // Standardunterer Abstand

    },
  


    widget6: {
      img: '',
      imagePreview: '/img/checkin.webp',
      text1: 'Check-In',
      text2: '15:00',
      text3: 'Check-Out',
      text4: '13:00',
      textColor1: '#000000',
      textColor2: '#333333',
      text1Weight: '300',
      text2Weight: '500',
      text1Size: '24',
      text2Size: '18',
      borderColor: '#e3e3e3',
      shadow: true, 
      border: true, 
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20',             // Standardunterer Abstand
      marginTop: '0',             // Standardunterer Abstand
      id: '',
      position: '6',
      logoWidth: '195',      // Pfad zum Vorschaubild für Widget1
      type: 'Widget6',
      name: 'Check-In',
      isSelected: false,
      cat1: 'Fancy' , 
      cat2: 'Image',  
      inverted: false,
      borderRadius: '25',           // Standardunterer Abstand      // Standardunterer Abstand
    },
    widget2: {
      img: '',
      logoWidth: '70',
      imagePreview: '/img/preview-2.webp', // Pfad zum Vorschaubild für Widget1
      text1: 'Cocktails at the Pool!',
      text2: 'Enjoy a refreshing summer cocktail at the pool bar while listening to the waves. Get your first drink on us.',
      textColor1: '#000000',
      textColor2: '#7b7b7b',
      text1Weight: '500',
      text2Weight: '300',
      text1Size: '32',
      height: '500',
      text2Size: '20',
      borderRadius: '25',
      backgroundColor: '#ffffff',
      borderColor: '#f5f5f5',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20',             // Standardunterer Abstand
      marginTop: '0',             // Standardunterer Abstand
      id: '',
      position: '2',
      type: 'Widget2',
      name: 'Fancy-Bulletpoint',
      isSelected: false,
      cat1: 'Fancy'   , 
      cat2: 'Image',  
      inverted: false,
    },
    widget15: {
      imgBanner: '',  // Pfad zum Standard-Bannerbild
      imagePreview: '/img/videobanner.webp', // Pfad zum Vorschaubild für Widget1
      text1: '1. Houserules',               // Standardtext 1
      text2: 'Welcome to a brief yet comprehensive video guide detailing our house rules and everything you need to know to enjoy a seamless and delightful stay at our accommodation.',
      imageWidth: '350', 
      height: '600',              // Standardtext 2
      textColor1: '#000000',            // Standardtextfarbe 1 (schwarz)
      textColor2: '#7b7b7b',            // Standardtextfarbe 2 (dunkelgrau)
      text1Size: '36',                // Standardgröße für Text 1
      text2Size: '20',                // Standardgröße für Text 2
      text1Weight: '500',              // Standardgewicht für Text 1
      text2Weight: '300',            // Standardgewicht für Text 2
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20', 
      marginTop: '20',   
      borderRadius: '25',           // Standardunterer Abstand      // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget15',                  // Typ des Widgets
      name: 'Video and Text',               // Anzeigename des Widgets
      position: '1',                      // Standardposition
      isSelected: false ,
      cat1: 'Fancy' , 
      cat2: 'Video',                 // Auswahlstatus initial auf false setzen
    },
    
    widget9: {
      imgBanner: '',  // Pfad zum Standard-Bannerbild
      imagePreview: '/img/sushivisit.webp', // Pfad zum Vorschaubild für Widget1
      text1: 'Did you visit our Sushi Bar?',               // Standardtext 1
      text2: 'Just steps from your room, on the terrace, awaits our exceptional sushi bar, where the renowned chef Kenji Takahara crafts culinary delights.',
      imageWidth: '90',               // Standardtext 2
      textColor1: '#000000',            // Standardtextfarbe 1 (schwarz)
      textColor2: '#7b7b7b',            // Standardtextfarbe 2 (dunkelgrau)
      text1Size: '32',                // Standardgröße für Text 1
      text2Size: '20', 
      height: '500', 
      imageWidth: '400',              // Standardgröße für Text 2
      text1Weight: '500',              // Standardgewicht für Text 1
      text2Weight: '300',            // Standardgewicht für Text 2
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20', 
      marginTop: '0',   
      borderRadius: '25',           // Standardunterer Abstand      // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget9',                  // Typ des Widgets
      name: 'Image and Text',               // Anzeigename des Widgets
      position: '1',                      // Standardposition
      isSelected: false ,    
      cat1: 'Basic' , 
      cat2: 'Image',                  // Auswahlstatus initial auf false setzen
    },
    widget10: {
      imgLogo: '',    
      imgBanner: '', 
      link: '',     // Pfad zum Standard-Logobild      // Pfad zum Standard-Logobild
      imagePreview: '/img/bonus1.webp', // Pfad zum Vorschaubild für Widget1
      text1: 'REVIEW BONUS',               // Standardtext 1
      text2: 'WRITE A REVIEW',
      text3: 'And get a special surprise.',
      logoWidth: '150',               // Standardtext 2
      textColor1: '#000000',            // Standardtextfarbe 1 (schwarz)
      textColor2: '#7b7b7b',   
      textColor3: '#000000',            // Standardtextfarbe 2 (dunkelgrau)      // Standardtextfarbe 2 (dunkelgrau)
      text1Size: '34',                // Standardgröße für Text 1
      text2Size: '20',                // Standardgröße für Text 2
      text1Weight: '500',              // Standardgewicht für Text 1
      text2Weight: '400',        
      backgroundColor: '#ffffff',    
      textBackgroundColor: '#f5f5f5',       // Standardhintergrundfarbe (weiß)      // Standardhintergrundfarbe (weiß)
      marginBottom: '20', 
      buttonBorderRadius: '100',
      marginTop: '20',   
      buttonText: 'I want to know more',
      buttonTextColor: '#ffffff',
      shadow: false, 
      border: true, 
      borderColor: '#dEdEdE',
      buttonBackgroundColor: '#0d0d0d',            // Standardunterer Abstand      // Standardunterer Abstand      // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget10',                  // Typ des Widgets
      name: 'Bonus Widget 1',               // Anzeigename des Widgets
      position: '1',                      // Standardposition
      isSelected: false ,
      cat1: 'Fancy' , 
      cat2: 'Bonus',    
      inverted: false,      // Auswahlstatus initial auf false setzen
    },
    widget7: {
      imagePreview: '/img/doubletext.webp', // Pfad zum Vorschaubild für Widget1
      text1: 'Welcome to our Hotel',               // Standardtext 1
      text2: 'This is a short description text of how happy we are to see you at our hotel. We will try our best to give you an unforgettable experience.',
      textColor1: '#000000',            // Standardtextfarbe 1 (schwarz)
      textColor2: '#7b7b7b',            // Standardtextfarbe 2 (dunkelgrau)
      text1Size: '36',                // Standardgröße für Text 1
      text2Size: '20', 
      height: '300',               // Standardgröße für Text 2
      text1Weight: '500',              // Standardgewicht für Text 1
      text2Weight: '300',            // Standardgewicht für Text 2
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20', 
      marginTop: '20',            // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget7',                  // Typ des Widgets
      name: 'Double-Text',               // Anzeigename des Widgets
      position: '6',                      // Standardposition
      isSelected: false ,
      cat1: 'Basic' , 
    },
    widget11: {
      imagePreview: '/img/simplebutton.webp',
      text1: 'Check out all Activities',  
      link: '',               // Standardtext 1      // Standardtext 1
      textColor1: '#000000',            // Standardtextfarbe 1 (schwarz)
      text1Size: '20', 
      border: true,  
      shadow: true,       // Standardgröße für Text 1
      text1Weight: '600',              // Standardgewicht für Text 1
      backgroundColor: '#ffffff',
      borderColor: '#dedede',
      borderThick: '1',
      marginBottom: '0', 
      marginTop: '0', 
      borderRadius: '100',           // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget11',                  // Typ des Widgets
      name: 'Simple-Button',               // Anzeigename des Widgets
      position: '7',                      // Standardposition
      isSelected: false ,
      cat1: 'Basic' , 
      cat2: 'Button',                  // Auswahlstatus initial auf false setzen
    },

    widget8: {
      img1: '',
      img2: '',
      imagePreview: '/img/preview-11.webp',
      text1: 'Check out all activities',  
      link: '',               // Standardtext 1      // Standardtext 1
      textColor1: '#000000',            // Standardtextfarbe 1 (schwarz)
      text1Size: '20', 
      border: false,  
      shadow: 'true',       // Standardgröße für Text 1
      text1Weight: '500',              // Standardgewicht für Text 1
      backgroundColor: '#ffffff',
      borderColor: '#000000',
      borderThick: '1',
      marginBottom: '20', 
      marginTop: '20', 
      imgWidth: '90',
      borderRadius: '100',           // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget8',                  // Typ des Widgets
      name: 'Elite-Button',               // Anzeigename des Widgets
      position: '7',                      // Standardposition
      isSelected: false ,
      cat1: 'Fancy' , 
      cat2: 'Button',   
      inverted: false,      // Auswahlstatus initial auf false setzen
    },
    widget12: {
      img1: '',
      img2: '',
      imagePreview: '/img/megabutton.webp',
      text1: 'Yoga Classes & Healing',  
      text2: 'Find out more about our very special yoga classes every Friday and Saturday 10AM.',  
      link: '',               // Standardtext 1      // Standardtext 1
      textColor1: '#000000',    
      textColor2: '#7b7b7b',            // Standardtextfarbe 1 (schwarz)      // Standardtextfarbe 1 (schwarz)
      text1Size: '18', 
      text2Size: '16', 
      border: false,  
      shadow: 'true',       // Standardgröße für Text 1
      text1Weight: '600', 
      text2Weight: '300',              // Standardgewicht für Text 1      // Standardgewicht für Text 1
      backgroundColor: '#ffffff',
      borderColor: '#000000',
      borderThick: '1',
      marginBottom: '20', 
      imgWidth: '150',
      marginTop: '20', 
      borderRadius: '25',           // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget12',                  // Typ des Widgets
      name: 'Mega-Button',               // Anzeigename des Widgets
      position: '7',                      // Standardposition
      isSelected: false,
      cat1: 'Fancy' , 
      cat2: 'Button',    
      inverted: false,      // Auswahlstatus initial auf false setzen
    },
    widget18: {
      img1: '',
      img2: '',
      imagePreview: '/img/preview-18.webp',
      text1: 'Check out the Weather',  
      text2: 'The latest weather report directly from your region on Bonusclub.',  
      link: '',               // Standardtext 1      // Standardtext 1
      textColor1: '#000000',    
      textColor2: '#7b7b7b',            // Standardtextfarbe 1 (schwarz)      // Standardtextfarbe 1 (schwarz)
      text1Size: '18', 
      text2Size: '16', 
      border: false,  
      shadow: 'true',       // Standardgröße für Text 1
      text1Weight: '600', 
      text2Weight: '300',              // Standardgewicht für Text 1      // Standardgewicht für Text 1
      backgroundColor: '#ffffff',
      borderColor: '#000000',
      borderThick: '1',
      marginBottom: '20', 
      imgWidth: '70',
      marginTop: '20', 
      borderRadius: '25',           // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget18',                  // Typ des Widgets
      name: 'Weather Widget',               // Anzeigename des Widgets
      position: '7',                      // Standardposition
      isSelected: false,
      cat1: 'Basic' , 
      cat2: 'Button',    
      inverted: false,      // Auswahlstatus initial auf false setzen
    },
    widget19: {
      img1: '',
      img2: '',
      imagePreview: '/img/preview-emergency.webp',
      text1: 'Emergency Button',  
      text2: 'Click this button to call the local ambulance. Only use for emergency!',  
      link: '',               // Standardtext 1      // Standardtext 1
      textColor1: '#000000',    
      textColor2: '#7b7b7b',            // Standardtextfarbe 1 (schwarz)      // Standardtextfarbe 1 (schwarz)
      text1Size: '18', 
      text2Size: '16', 
      border: false,  
      shadow: 'true',       // Standardgröße für Text 1
      text1Weight: '600', 
      text2Weight: '300',              // Standardgewicht für Text 1      // Standardgewicht für Text 1
      backgroundColor: '#ffffff',
      borderColor: '#000000',
      borderThick: '1',
      marginBottom: '20', 
      imgWidth: '70',
      marginTop: '20', 
      borderRadius: '25',           // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget19',                  // Typ des Widgets
      name: 'Emergency Widget',               // Anzeigename des Widgets
      position: '7',                      // Standardposition
      isSelected: false,
      cat1: 'Basic' , 
      cat2: 'Button',    
      inverted: false,      // Auswahlstatus initial auf false setzen
    },
    widget13: {
      img: '',
      imagePreview: '/img/addy.webp',
      logoWidth: '80',      // Pfad zum Vorschaubild für Widget1
      text1: 'The Address',
      text2: 'Schwarzhorngasse 1',
      text3: '1050, Vienna',
      text4: 'Austria',
      textColor1: '#000000',
      textColor2: '#333333',
      textColor3: '#FFFFFF',
      textBackground: '#F5BF32',
      borderColor: '#000000',
      border: false, 
      text1Weight: '500',
      text2Weight: '300',
      text1Size: '28',
      text2Size: '20',
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20',             // Standardunterer Abstand
      marginTop: '0',    
      shadow: false,          // Standardunterer Abstand
      id: '',
      position: '2',
      type: 'Widget13',
      name: 'Address Card',
      isSelected: false,
      cat1: 'Fancy' , 
      cat2: 'Basic',  
    },
    widget5: {
      img: '',
      imagePreview: '/img/preview-5.png',
      text1: 'This is a normal question.',
      text2: 'And this is a great answer.',
      textColor1: '#000000',
      textColor2: '#333333',
      text1Weight: '400',
      text2Weight: '500',
      borderRadius: '25',           // Standardunterer Abstand      // Standardunterer Abstand
      text1Size: '20',
      text2Size: '18',
      borderColor: '#e3e3e3',
      shadow: true, 
      border: true, 
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20',             // Standardunterer Abstand
      marginTop: '0',             // Standardunterer Abstand
      id: '',
      position: '5',
      type: 'Widget5',
      name: 'FAQ',
      isSelected: false,
      cat1: 'Fancy' , 
      cat2: 'Basic',  
    },
    widget14: {
      imgBanner: '',  // Pfad zum Standard-Bannerbild
      imgLogo: '',      // Pfad zum Standard-Logobild
      imagePreview: '/img/team.webp', // Pfad zum Vorschaubild für Widget1
      text1: 'Get in touch with our team',               // Standardtext 1
      text2: 'office@myhotel.com',
      text3: '012343423',
      textColor1: '#ffffff',            // Standardtextfarbe 1 (schwarz)
      textColor2: '#ffffff',            // Standardtextfarbe 2 (dunkelgrau)
      text1Size: '36',                // Standardgröße für Text 1
      text2Size: '20',                // Standardgröße für Text 2
      text1Weight: '500',              // Standardgewicht für Text 1
      text2Weight: '300',            // Standardgewicht für Text 2
      backgroundColor: '#1f1f1f',       // Standardhintergrundfarbe (weiß)
      marginBottom: '0', 
      marginTop: '0',
      paddingBottom: '50',             // Standardunterer Abstand
      paddingTop: '50',
      borderRadius: '0',             // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget14',                  // Typ des Widgets
      name: 'Team',
      border: false,       // Anzeigename des Widgets
      position: '1',    
      shadow: false,          // Standardunterer Abstand      // Standardposition
      isSelected: false ,
      cat1: 'Fancy' , 
      cat2: 'Basic',  
      inverted: true,      // Auswahlstatus initial auf false setzen
    },

    widget17: {
      imagePreview: '/img/imprint.webp',
      text1: '@BonusClub 2024', 
      text2: 'Imprint',   
      text3: 'Terms of Use',   
      imprintLink: '',  
      termsLink: '',               // Standardtext 1      // Standardtext 1      // Standardtext 1      // Standardtext 1
      textColor1: '#000000',  
      textColor2: '#000000',            // Standardtextfarbe 1 (schwarz)      // Standardtextfarbe 1 (schwarz)
      text1Size: '16', 
      text2Size: '14', 
      border: false,  
      shadow: false,       // Standardgröße für Text 1
      text1Weight: '500',  
      text2Weight: '400',              // Standardgewicht für Text 1      // Standardgewicht für Text 1
      backgroundColor: '#ffffff',
      borderColor: '#dedede',
      marginBottom: '0', 
      marginTop: '40', 
      borderRadius: '0',           // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget17',                  // Typ des Widgets
      name: 'Imprint',               // Anzeigename des Widgets
      position: '7',                      // Standardposition
      isSelected: false ,
      cat1: 'Basic' , 
      cat2: 'Button',                  // Auswahlstatus initial auf false setzen
    },
   
    widget20: {
      imagePreview: '/img/title_placeholder.webp',
      text1: 'This is the Title',  
      textColor1: '#000000',  
      text1Size: '42', 
      textAlign: 'center',
      marginLeft: '10',
      marginRight: '10',
      lineHeight: '1',
      letterSpacing: '0',
      height: '70',
      border: false,  
      shadow: false,       // Standardgröße für Text 1
      text1Weight: '600',  
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      borderColor: '#dedede',
      marginBottom: '20', 
      marginTop: '20', 
      borderRadius: '0',           // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget20',                  // Typ des Widgets
      name: 'Title',               // Anzeigename des Widgets
      position: '20',                      // Standardposition
      isSelected: false ,
      cat1: 'Basic' , 
    },

    widget21: {
      imagePreview: '/img/desc_placeholder.webp',
      text1: 'Welcome to Oceanview Retreat, where comfort meets luxury. Nestled on a serene coastline with breathtaking views of the sea, our hotel offers the perfect escape from the hustle and bustle of everyday life.',  
      textColor1: '#000000',  
      text1Size: '18', 
      textAlign: 'center',
      marginLeft: '10',
      marginRight: '10',
      lineHeight: '1',
      letterSpacing: '0',
      height: '70',
      border: false,  
      shadow: false,       // Standardgröße für Text 1
      text1Weight: '300',  
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      borderColor: '#dedede',
      marginBottom: '20', 
      marginTop: '20', 
      borderRadius: '0',           // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget21',                  // Typ des Widgets
      name: 'Text',               // Anzeigename des Widgets
      position: '21',                      // Standardposition
      isSelected: false ,
      cat1: 'Basic' , 
    },
    widget22: {
      imgBanner: '',  // Pfad zum Standard-Bannerbild
      imagePreview: '/img/image_placeholder.webp', // Pfad zum Vorschaubild für Widget1
      imageWidth: '90',               // Standardtext 2
      height: '350',
      alignItems: 'center',
      maxHeight: '300', 
      imageWidth: '400',              // Standardgröße für Text 2
      backgroundColor: '#ffffff',       // Standardhintergrundfarbe (weiß)
      marginBottom: '20', 
      marginTop: '0',   
      borderRadius: '25',           // Standardunterer Abstand      // Standardunterer Abstand
      id: '',                    // Eindeutige ID für das Widget
      type: 'Widget22',                  // Typ des Widgets
      name: 'Image',               // Anzeigename des Widgets
      position: '22',                      // Standardposition
      isSelected: false ,    
      cat1: 'Basic' , 
      cat2: 'Image',                  // Auswahlstatus initial auf false setzen
    },
    
  };

  
  