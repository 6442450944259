import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, get, set, onValue } from 'firebase/database';
import ImageWidget from './WidgetTypes/ImageWidget';
import TopWidget from './WidgetTypes/TopWidget';
import FancyBullet from './WidgetTypes/FancyBullet';
import IconFeature from './WidgetTypes/IconFeature';
import WifiCard from './WidgetTypes/WifiCard';
import FaqWidget from './WidgetTypes/FaqWidget';
import CheckInCard from './WidgetTypes/CheckInCard';
import DoubleText from './WidgetTypes/DoubleText';
import EliteButton from './WidgetTypes/EliteButton';
import './PagePublic.css';
import PicText from './WidgetTypes/PicText';
import Bonus1 from './WidgetTypes/Bonus1';
import BigButton from './WidgetTypes/BigButton';
import MegaButton from './WidgetTypes/MegaButton';
import AddyCard from './WidgetTypes/AddyCard';
import EndWidget from './WidgetTypes/EndWidget';
import VideoText from './WidgetTypes/VideoText';
import EmptyPage from './EmptyPage';
import PageLoading from './PageLoading';
import HeaderWidget from './WidgetTypes/HeaderWidget';
import ImprintBar from './WidgetTypes/ImprintBar';
import GoogleTranslate from './WidgetTypes/GoogleTranslate';
import WeatherButton from './WidgetTypes/WeatherButton';
import EmergencyWidget from './WidgetTypes/EmergencyWidget';
import TitleWidget from './WidgetTypes/TitleWidget';
import TextWidget from './WidgetTypes/TextWidget';
import PicWidget from './WidgetTypes/PicWidget';

function PagePublic() {
  const { customRoute } = useParams();
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getDatabase();

  useEffect(() => {
    const normalizedRoute = customRoute.toLowerCase();
    console.log("Normalized Custom Route: ", normalizedRoute);

    // Abrufen der pageId und userId basierend auf der normalizedRoute
    const publicPageRef = ref(db, `/publicPages/${normalizedRoute}`);
    get(publicPageRef).then((snapshot) => {
      if (snapshot.exists()) {
        const { pageId, userId } = snapshot.val();
        console.log("Page ID: ", pageId);
        console.log("User ID: ", userId);

        // Überprüfen, ob die Seite aktiv ist
        const isActiveRef = ref(db, `/pages/${pageId}/isActive`);
        get(isActiveRef).then((isActiveSnapshot) => {
          const isActive = isActiveSnapshot.val();
          console.log("Is Active: ", isActive);

          if (isActive) {
            // Update des View-Counters
            const viewCountRef = ref(db, `/pages/${pageId}/viewCounter`);
            get(viewCountRef).then((viewSnapshot) => {
              const currentCount = viewSnapshot.val() || 0;
              console.log("Current View Count: ", currentCount);
              set(viewCountRef, currentCount + 1);
            });

            // Laden der Seitendaten
            const pageRef = ref(db, `/pages/${pageId}`);
            onValue(pageRef, (pageSnapshot) => {
              const data = pageSnapshot.val();
              console.log("Page Data: ", data);
              setPageData(data);
              setLoading(false);
            });
          } else {
            console.log("Page is not active.");
            setLoading(false);
          }
        });
      } else {
        console.log("No page found for this custom route.");
        setLoading(false);
      }
    });
  }, [db, customRoute]);

  const openWhatsApp = (number) => {
    window.open(`https://wa.me/${number}`, '_blank');
  };

  const imageUrl = pageData && pageData.livePageImg ? pageData.livePageImg : "/img/BonusClub_Logo.webp";

  if (loading) {
    return <div className="loading">
      <PageLoading />
    </div>;
  }

  if (!pageData || !pageData.widgets) {
    return <div>
      <EmptyPage />
    </div>;
  }

  const renderWidget = (widget) => {
    switch (widget.type) {
      case 'Widget1':
        return <TopWidget data={widget} isEditMode={false} />;
      case 'Widget2':
        return <FancyBullet data={widget} isEditMode={false} />;
      case 'Widget3':
        return <IconFeature data={widget} isEditMode={false} />;
      case 'Widget4':
        return <WifiCard data={widget} isEditMode={false} />;
      case 'Widget5':
        return <FaqWidget data={widget} isEditMode={false} />;
      case 'Widget6':
        return <CheckInCard data={widget} isEditMode={false} />;
      case 'Widget7':
        return <DoubleText data={widget} isEditMode={false} />;
      case 'Widget8':
        return <EliteButton data={widget} isEditMode={false} />;
      case 'Widget9':
        return <PicText data={widget} isEditMode={false} />;
      case 'Widget10':
        return <Bonus1 data={widget} isEditMode={false} />;
      case 'Widget11':
        return <BigButton data={widget} isEditMode={false} />;
      case 'Widget12':
        return <MegaButton data={widget} isEditMode={false} />;
      case 'Widget13':
        return <AddyCard data={widget} isEditMode={false} />;
      case 'Widget14':
        return <EndWidget data={widget} isEditMode={false} />;
      case 'Widget15':
        return <VideoText data={widget} isEditMode={false} />;
      case 'Widget16':
        return <HeaderWidget data={widget} isEditMode={false} />;
      case 'Widget17':
        return <ImprintBar data={widget} isEditMode={false} />;
        case 'Widget18':
          return <WeatherButton data={widget} isEditMode={false} />;
       case 'Widget19':
        return <EmergencyWidget data={widget} isEditMode={false} />;
       case 'Widget20':
         return <TitleWidget data={widget} isEditMode={false} />;
       case 'Widget21':
        return <TextWidget data={widget} isEditMode={false} />;
        case 'Widget22':
          return <PicWidget data={widget} isEditMode={false} />;
   
        default:
        return <div>Unbekanntes Widget</div>;
    }
  };

  const pageBackgroundColor = pageData?.backgroundColor || '#FFFFFF'; // Standardfarbe Weiß, wenn keine Hintergrundfarbe angegeben ist

  return (
    <div className="page_public" style={{ backgroundColor: pageBackgroundColor }}>
      <div className="page_public_widgets_wrapper">
      {pageData.translate1 || pageData.translate2 ? <GoogleTranslate data={pageData} /> : null}
        {pageData.widgets.map((widget, index) => (
          <div key={index} className="widget-boss-wrapper" style={{ backgroundColor: pageBackgroundColor }}>
            {renderWidget(widget)}
          </div>
        ))}
        {pageData.whatsappStatus && (
          <img
            className="whatsapp-button-live"
            src="/img/whatsapp.svg"
            alt="WhatsApp"
            onClick={() => openWhatsApp(pageData.whatsappNumber)}
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
      <img src={imageUrl} alt="Seitenbild" className="page-image" />
    </div>
  );
}

export default PagePublic;

