import React from 'react';
import './ImageSizeSlider.css'; // Make sure to create a corresponding CSS file

const LineHeightSlider = ({ label, value, setValue, min = 1, max = 3, step = 0.1 }) => {
  const handleSliderChange = (e) => {
    setValue(parseFloat(e.target.value)); // Ensure the value is a float
  };

  const handleInputChange = (e) => {
    // Allow numeric input with decimals and enforce range limits
    let inputValue = e.target.value;
    if (/^\d*(\.\d*)?$/.test(inputValue)) {
      const parsedValue = parseFloat(inputValue);
      if (!isNaN(parsedValue)) {
        setValue(Math.max(min, Math.min(max, parsedValue)));
      } else if (inputValue === "") {
        setValue(""); // Allow clearing the input temporarily
      }
    }
  };

  return (
    <div className="image-size-slider-container">
      <label className="slided-label">{label}</label>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        className="slided"
        id="line-height-slider"
        onChange={handleSliderChange}
      />
      <input
        type="text"
        className="slided-value-input"
        value={value}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default LineHeightSlider;
