import './TopWidget.css'; 
import './WidgetStyle.css';
import { useFont } from '../../Design/FontContext';
import React, { useState } from 'react';
import DeleteModal from './DeleteModal';

function appendPx(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    return `${value}px`;
}

function DoubleText({ data, onRemove, isSelected, isEditMode }) {
    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        height: appendPx(data.height),
        minHeight: 'fit-content',
        marginTop: appendPx(data.marginTop),
        border: getBorderStyle(),
    };

    const deleteLogo = '/img/delete.svg';
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            if (isSelected) {
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }

    const handleDelete = (event) => {
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };

    const fonts = useFont();

    return (
        <div className="top_widget" style={widgetStyle}>
            <div>
                {isEditMode && (
                    <button className="delete_button" onClick={() => setShowDeleteModal(true)}>
                        <img className="delete_logo" src={deleteLogo} alt="Löschen" />
                    </button>
                )}
                {isEditMode && showDeleteModal && (
                    <DeleteModal
                        isOpen={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={(event) => handleDelete(event, data.id)}
                    />
                )}
            </div>
            <h5 style={{
                color: data.textColor1,
                fontSize: appendPx(data.text1Size),
                fontWeight: data.text1Weight,
                fontFamily: fonts.globalBigFont,
            }}>
                {data.text1}
            </h5>
            <p style={{
                color: data.textColor2,
                fontSize: appendPx(data.text2Size),
                fontWeight: data.text2Weight,
                fontFamily: fonts.globalSmallFont,
            }}>
                {data.text2}
            </p>
        </div>
    );
}

export default DoubleText;
