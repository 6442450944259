import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get, set, onValue } from 'firebase/database';
import ImageWidget from '../WidgetTypes/ImageWidget';
import TopWidget from '../WidgetTypes/TopWidget';
import FancyBullet from '../WidgetTypes/FancyBullet';
import IconFeature from '../WidgetTypes/IconFeature';
import WifiCard from '../WidgetTypes/WifiCard';
import FaqWidget from '../WidgetTypes/FaqWidget';
import CheckInCard from '../WidgetTypes/CheckInCard';
import DoubleText from '../WidgetTypes/DoubleText';
import EliteButton from '../WidgetTypes/EliteButton';
import '../PagePublic.css'
import PicText from '../WidgetTypes/PicText';
import Bonus1 from '../WidgetTypes/Bonus1';
import BigButton from '../WidgetTypes/BigButton';
import MegaButton from '../WidgetTypes/MegaButton';
import AddyCard from '../WidgetTypes/AddyCard';
import EndWidget from '../WidgetTypes/EndWidget';
import VideoTextEdit from '../WidgetEditTypes/VideoTextEdit';
import VideoText from '../WidgetTypes/VideoText';
import HeaderWidget from '../WidgetTypes/HeaderWidget';
import ImprintBar from '../WidgetTypes/ImprintBar';
import WeatherButton from '../WidgetTypes/WeatherButton';
import EmergencyWidget from '../WidgetTypes/EmergencyWidget';
import TitleWidget from '../WidgetTypes/TitleWidget';
import TextWidget from '../WidgetTypes/TextWidget';
import PicWidget from '../WidgetTypes/PicWidget';


function DemoPagePublic() {
  const { customRoute } = useParams(); // Verwenden von customRoute statt pageId
  const [pageData, setPageData] = useState(null);
  const db = getDatabase();
  const [loading, setLoading] = useState(true);
  const [translateWidgetLoaded, setTranslateWidgetLoaded] = useState(false);

  useEffect(() => {
    // Abrufen der pageId und userId basierend auf der customRoute
    const publicPageRef = ref(db, `/demoPublicPages/${customRoute}`);
    get(publicPageRef).then((snapshot) => {
      if (snapshot.exists()) {
        const { pageId, userId } = snapshot.val();


        // Laden der Seitendaten
        const pageRef = ref(db, `/demoPages/${userId}/${pageId}`);
        onValue(pageRef, (pageSnapshot) => {
          const data = pageSnapshot.val();
          setPageData(data);
          setLoading(false);
          setTranslateWidgetLoaded(true); // Setzen Sie dies auf true, sobald die Widgets geladen sind

        });
      } else {
        setLoading(false);
      }
    });
  }, [db, customRoute]);

  const imageUrl = pageData && pageData.livePageImg ? pageData.livePageImg : "/img/BonusClub_Logo.webp";


  if (loading) {
    return <div className="loading">
      <div className="spinner3" id="spinnerle" >
        
      </div></div>; // Spinner anstelle des "Loading..." Textes
}


  if (!pageData || !pageData.widgets) {
    return <div>Seite nicht gefunden.</div>;
  }

  const renderWidget = (widget) => {
    switch (widget.type) {
      case 'Widget1':
        return <TopWidget data={widget} isEditMode={false} />;
      case 'Widget2':
        return <FancyBullet data={widget} isEditMode={false} />;
      case 'Widget3':
        return <IconFeature data={widget} isEditMode={false} />;
      case 'Widget4':
        return <WifiCard data={widget} isEditMode={false} />;
      case 'Widget5':
        return <FaqWidget data={widget} isEditMode={false} />;
      case 'Widget6':
        return <CheckInCard data={widget} isEditMode={false} />;
      case 'Widget7':
        return <DoubleText data={widget} isEditMode={false} />;
      case 'Widget8':
        return <EliteButton data={widget} isEditMode={false} />;
        case 'Widget9':
        return <PicText data={widget} isEditMode={false} />;
        case 'Widget10':
          return <Bonus1 data={widget} isEditMode={false} />;
          case 'Widget11':
            return <BigButton data={widget} isEditMode={false} />;
            case 'Widget12':
            return <MegaButton data={widget} isEditMode={false} />;
            case 'Widget13':
              return <AddyCard data={widget} isEditMode={false} />;
              case 'Widget14':
                return <EndWidget data={widget} isEditMode={false} />;
                case 'Widget15':
                  return <VideoText data={widget} isEditMode={false} />;
                  case 'Widget16':
                    return <HeaderWidget data={widget} isEditMode={false} />;
                    case 'Widget17':
                      return <ImprintBar data={widget} isEditMode={false} />;
                      case 'Widget18':
                        return <WeatherButton data={widget} isEditMode={false} />;
                        case 'Widget19':
                          return <EmergencyWidget data={widget} isEditMode={false} />;
                          case 'Widget20':
                            return <TitleWidget data={widget} isEditMode={false} />;
                          case 'Widget21':
                           return <TextWidget data={widget} isEditMode={false} />;
                           case 'Widget22':
                             return <PicWidget data={widget} isEditMode={false} />;
                      
                
                
                          default:
        return <div>Unknown Widget</div>;
    }
  };

  const pageBackgroundColor = pageData?.backgroundColor || '#FFFFFF'; // Standardfarbe Weiß, wenn keine Hintergrundfarbe angegeben ist


  return (
    <div className="page_public">
        <div className="page_public_widgets_wrapper" style={{ backgroundColor: pageBackgroundColor }}>
      {pageData.widgets.map((widget, index) => (
        <div key={index} className="widget-boss-wrapper" style={{ backgroundColor: pageBackgroundColor }} >
          {renderWidget(widget)}
        </div>
      ))}
    </div>
    <img src={imageUrl} alt="Seitenbild" />
    </div>


  );
}

export default DemoPagePublic;
