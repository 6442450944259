import './TopWidget.css'; 
import './WidgetStyle.css'; 
import { useFont } from '../../Design/FontContext';
import React, { useState } from 'react';
import DeleteModal from './DeleteModal';

function appendPx(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    return `${value}px`;
}

function appendPercent(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    const percentValue = Math.min(Number(value), 100);
    return `${percentValue}%`;
}

function PicWidget({ data, onRemove, isSelected, isEditMode }) {
    const defaultBannerSrc = '/img/sushibar.webp'; 
    const deleteLogo = '/img/delete.svg'; 

    const fonts = useFont();

    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            if (isSelected) {
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }

    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        minHeight: appendPx(data.height),
        marginTop: appendPx(data.marginTop),
        border: getBorderStyle(),
        width: `calc(100% - ${appendPx(data.marginLeft)} - ${appendPx(data.marginRight)})`,
        alignItems: data.alignItems,
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event) => {
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };

    return (
        <div className="top_widget" style={widgetStyle}>
            <img 
                src={data.imgBanner || defaultBannerSrc} 
                className={`top_widget_banner_img_2 ${data.headerStyle}`}
                alt="Banner" 
                style={{
                    borderRadius: appendPx(data.borderRadius), 
                    width: appendPercent(data.imageWidth),
                    maxHeight: appendPx(data.maxHeight),
                    marginBottom: '0px',
                    objectFit: 'cover',
                    marginLeft: appendPx(data.marginLeft),
                    marginRight: appendPx(data.marginRight),
                }}
            />
            <div>
                {isEditMode && (
                    <button className="delete_button" onClick={() => setShowDeleteModal(true)}>
                        <img className="delete_logo" src={deleteLogo} alt="Löschen" />
                    </button>
                )}
                {isEditMode && showDeleteModal && (
                    <DeleteModal
                        isOpen={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={(event) => handleDelete(event, data.id)}
                    />
                )}
            </div>
           
        </div>
    );
}

export default PicWidget;
