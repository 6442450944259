import React, { useState, useEffect } from 'react';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import './TopWidgetEdit.css';
import ImageSizeSlider from '../ImageSizeSlider';
import StyleSelector from '../StyleSelector';

function PicWidgetEdit({ widget, onUpdate, colors }) {
    const [editData, setEditData] = useState({ ...widget });
    const [imagePreview, setImagePreview] = useState(widget.imgBanner || '');
    const [imagePreview2, setImagePreview2] = useState(widget.imgLogo || '');
    const [isUploadingBanner, setIsUploadingBanner] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const auth = getAuth();
    const [position, setPosition] = useState(widget.position);
    const [headerStyle, setHeaderStyle] = useState('style1'); // 'style1' as default layout

    useEffect(() => {
        setImagePreview(widget.imgBanner || '');
        setImagePreview2(widget.imgLogo || '');
        setHeaderStyle(widget.headerStyle || 'style1');

    }, [widget.imgBanner, widget.imgLogo]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Restrict marginLeft and marginRight to a maximum of 50px
        if (name === "marginLeft" || name === "marginRight") {
            const numericValue = parseInt(value, 10) || 0; // Convert to integer
            if (numericValue > 50) {
                return; // Do not update the state if the value exceeds 50
            }
        }
    
        const updatedEditData = { ...editData, [name]: value };
        setEditData(updatedEditData);
        onUpdate(updatedEditData);
    };
    
    

    const handleImageUploadBanner = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadImage(file, 'imgBanner', setImagePreview, setIsUploadingBanner);
        }
    };

    const handleImageUploadLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadImage(file, 'imgLogo', setImagePreview2, setIsUploadingLogo); // Corrected to setImagePreview2 and setIsUploadingLogo
        }
    };

    const handleStyleChange = (style) => {
        setHeaderStyle(style);
        handleInputChange({ target: { name: 'headerStyle', value: style } });
    };

   const uploadImage = (file, fieldName, setPreview, setUploadingState) => {
    if (!file || file.size > 1024 * 1024) {
        alert('File is too big (max. 1MB).');
        return;
    }

    setUploadingState(true);
    const storage = getStorage();
    const userId = auth.currentUser.uid;
    const imageRef = storageRef(storage, `pages/${userId}/${widget.id}/${file.name}`);
    const uploadTask = uploadBytesResumable(imageRef, file);

    uploadTask.on('state_changed', 
        (snapshot) => {
            // Optional: Show upload progress
        }, 
        (error) => {
            console.error('Upload error:', error);
            setUploadingState(false);
        }, 
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                const updatedEditData = { ...editData, [fieldName]: downloadURL };
                setEditData(updatedEditData);
                setPreview(downloadURL);
                setUploadingState(false);

                // Save the updated data immediately in the database
                onUpdate({ ...updatedEditData, position: position });
            });
        }
    );
};

    const handleSave = () => {
        console.log("Saved position:", position);
        const updatedData = { ...editData, position: position };
        onUpdate(updatedData);
    };

    const styleOptions = [
        { id: 'style1', iconPath: '/img/Header_Style_5.svg', text: 'Fancy' },
        { id: 'style2', iconPath: '/img/Header_Style_4.svg', text: 'Rounded' },
        { id: 'style3', iconPath: '/img/Header_Style_2.svg', text: 'Style 3' },
    ];


    const handleTextAlignChange = (alignment) => {
        const updatedEditData = { ...editData, alignItems: alignment };
        setEditData(updatedEditData);
        onUpdate(updatedEditData);
    };


    return (
        <div>
            <div className="widget-trenner" style={{ marginTop: '20px' }}>  
                <h3>{editData.name}</h3>
                <hr className="hrrrr" />
            </div>
            <div className="top-widget-edit-container">
                <label>Image Shape</label>
                <StyleSelector
                    options={styleOptions}
                    selectedStyle={headerStyle}
                    onStyleChange={handleStyleChange}
                />
                <div className="widget_upload">
                    <label>Image Banner</label>
                    <label className="custom-upload-box" htmlFor="bannerImageUpload">
                        <input 
                            id="bannerImageUpload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageUploadBanner}
                            style={{ display: 'none' }}
                        />
                        {isUploadingBanner ? (
                            <div className="spinner-container">
                                <div className="spinner-circle"></div>
                                <p>Uploading...</p>
                            </div>
                        ) : imagePreview ? (
                            <img className="uploaded-image-preview" src={imagePreview} alt="Preview" />
                        ) : (
                            <div className="upload-image-preview">
                                <img src="/img/upload1.svg" />
                                <span>Click here to upload your banner</span>
                            </div>
                        )}
                    </label>
                </div>


                <ImageSizeSlider
                    min="0"
                    max="100"
                    label=""
                    width={editData.imageWidth}
                    setWidth={(value) => handleInputChange({ target: { name: 'imageWidth', value } })}
                />
                    <ImageSizeSlider
                    min="0"
                    max="1000"
                    label=""
                    width={editData.maxHeight}
                    setWidth={(value) => handleInputChange({ target: { name: 'maxHeight', value } })}
                />
                <div className="widget-trenner" style={{ marginTop: '30px', marginBottom: '0px', display: 'flex' }}>
                        <h3>Colors</h3>
                        <div className="widget-color-previews">
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color1 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color2 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color3 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color4 }}></div>
                        </div>
                        <div className="widget-color-previews">
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color1 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color2 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color3 }}></div>
                            <div className="widget-color-preview" style={{ backgroundColor: colors.color4 }}></div>
                        </div>
                    </div>
                    <hr className="hrrrr" style={{marginBottom: '20px'}}/>
                <div id="double_color_picker">
                    <div className="input-group">
                        <label htmlFor="backgroundColor">Background</label>
                        <div className="colorPicker">
                            <input
                                id="backgroundColor"
                                type="color"
                                name="backgroundColor"
                                value={editData.backgroundColor}
                                onChange={handleInputChange}
                                className="color-input"
                            />
                            <div
                                className="custom-color-display-2"
                                style={{ backgroundColor: editData.backgroundColor }}
                            ></div>
                            <img className="color-picker-icon" src="/img/background.svg" alt="" />
                        </div>
                    </div>
                </div>


                <div className="widget-trenner">  
                    <h3>More Settings</h3>
                    <hr className="hrrrr" />
                    <label style={{ fontSize: '12px', textTransform: 'uppercase', color: 'grey', marginTop: '30px' }}>Background Height</label>
                    <div className="input-group">
                    <label style={{marginTop: '30px'}}>Image Alignment</label>
                    <div className="text-align-buttons">
                    <button
    onClick={() => handleTextAlignChange('flex-start')}
    className={editData.alignItems === 'flex-start' ? 'active' : ''}
>
    Start
</button>
<button
    onClick={() => handleTextAlignChange('center')}
    className={editData.alignItems === 'center' ? 'active' : ''}
>
    Center
</button>
<button
    onClick={() => handleTextAlignChange('flex-end')}
    className={editData.alignItems === 'flex-end' ? 'active' : ''}
>
    End
</button>

                    </div>
                </div>
                    <ImageSizeSlider
                        min="50"
                        max="1000"
                        label="Height"
                        width={editData.height}
                        setWidth={(value) => handleInputChange({ target: { name: 'height', value } })}
                    />
                </div>
                <div className="input-container"> 
                    <div className="input-group">
                        <label htmlFor="marginTop">Space Top</label>
                        <input 
                            id="marginTop"
                            type="text" 
                            name="marginTop" 
                            value={editData.marginTop}
                            onChange={handleInputChange} 
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/element.svg" alt="" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="marginBottom">Space Bottom</label>
                        <input 
                            id="marginBottom"
                            type="text" 
                            name="marginBottom" 
                            value={editData.marginBottom}
                            onChange={handleInputChange} 
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/element.svg" style={{ rotate: '180deg' }} alt="" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="marginBottom">Space Left</label>
                        <input
                            id="marginLeft"
                            type="text"
                            name="marginLeft"
                            value={editData.marginLeft}
                            onChange={handleInputChange}
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/element.svg" style={{ rotate: '270deg' }} alt="" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="marginBottom">Space Right</label>
                        <input
                            id="marginRight"
                            type="text"
                            name="marginRight"
                            value={editData.marginRight}
                            onChange={handleInputChange}
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/element.svg" style={{ rotate: '90deg' }} alt="" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="borderRadius">Image Edges</label>
                        <input 
                            id="borderRadius"
                            type="text" 
                            name="borderRadius" 
                            value={editData.borderRadius}
                            onChange={handleInputChange} 
                            className="widgetInputField"
                        />
                        <img className="label-icon" src="/img/maximize.svg" alt="" />
                    </div>
                </div>

                <div className="save-button-banner"></div>                  
            </div>
        </div>
    );
}    

export default PicWidgetEdit;